<template>
  <div class="mainpageboxs">
    <div class="header-img">
      <!-- <img v-if="banner_list.length>0" :src="banner_list.length>0?banner_list[0].ads_pic:''" alt /> -->
      <img :src="require('@/assets/gs/bg_hj.png')" alt />
    </div>
    <div class="header-search">
      <img
        :src="require('@/assets/search.png')"
        style="width: 26px; height: 26px; margin-left: 10px"
      />
      <input type="text" placeholder="请输入要查询的内容" v-model="searchForm.name" />
      <span @click="search" style="cursor: pointer;">搜索</span>
    </div>
    <div class="container">
      <div v-if="lists.length !=0">
        <div class="lists">
          <div class="list-item" v-for="(item,index) in lists" :key="index">
            <template v-if="item.isShow == 0">
              <p class="title" :title="item.name">
                <span style="color:#999;">{{ index +1 }}、</span>
                <el-link :href="item.filePath" target="_blank">{{item.name}}</el-link>
              </p>
              <span class="timer">时间：{{ item.date }}</span>
            </template>
          </div>
        </div>
        <div style="text-align: center; margin: 50px 0">
          <Page
            :total="total"
            show-total
            @on-change="pageChange"
            :current="searchForm.pn"
            show-elevator
            :page-size="searchForm.size"
          />
        </div>
      </div>
      <div v-else style="text-align: center;font-size: 18px;color: #999;">
        暂无数据
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { queryCertificateList } from "@/api/api2"
export default {
  name:"Environment",
  data(){
    return {
      searchForm:{
        name:"",
        pn:1,
        size:10,
        type:'2'
      },
      lists:[],
      total:0,

    }
  },
  mounted(){
    this.getLists()
  },
  methods:{
    search(){
      this.searchForm.pn = 1
      this.getLists()
    },
    getLists(){
      queryCertificateList(this.searchForm).then(res =>{
        this.lists = []
        if(res.code == '200'){
          res.result.forEach(item => {
            item.date = dayjs(item.date).format('YYYY-MM-DD');
            this.lists.push(item)
          });
          this.total = res.total
        }
      })
    },
    pageChange(val){
      this.searchForm.pn = val
      this.getLists()
    }
  }
}
</script>
<style lang="less" scoped>
.mainpageboxs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-img img {
  width: 100%;
  display: block;
  min-height: 200px;
}
.header-search {
  z-index: 1;
  margin-top: -25px;
  width: 65%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 2px #e2e2e2;
  input {
    padding-left: 20px;
    height: 50px;
    border: none;
    width: calc(100% - 120px);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    outline: none;
  }
  span {
    display: inline-block;
    width: 120px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: #5075ec;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 8px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
.container {
  width: 65%;
  padding: 80px 20px 30px 20px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  margin-top: 80px;
  margin-bottom: 60px;
  .lists {
    .list-item {
      display: flex;
      line-height: 32px;
      justify-content: space-between;
      .title {
        width: 80%;
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #666;
        cursor: pointer;
        &:hover{
          color: #5075ec;
        }
      }
      .timer {
        color: #999;
        font-size: 14px;
      }
    }
    
  }
}
</style>