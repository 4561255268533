<template>
  <div class="container">
    <div class="head">
      <div class="head-left">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <el-form-item label="关键词">
            <el-input size="small" clearable v-model="searchForm.name" placeholder="输入关键词搜索"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="queryForm">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="head-right">
        <el-button size="small" type="primary" @click="showDialog('','add')">添加</el-button>
      </div>
    </div>
    <div class="content">
      <el-table
        border
        size="small"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="80"
          align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="文件名">
        </el-table-column>
        <el-table-column
          prop="fileName"
          label="文件名称">
          <template slot-scope="scope">
            <el-link :href="scope.row.filePath" target="_blank">{{scope.row.fileName}}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="时间"
          width="180"
          align="center">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="220">
          <template slot-scope="scope">
            <el-button v-if="scope.row.isShow == 0" size="mini" type="warning" @click="handleIshow(scope.row)">隐藏</el-button>
            <el-button v-else size="mini" type="success" @click="handleIshow(scope.row)">开启</el-button>
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="showDialog(scope.row,'edit')"></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteRow(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin: 50px 0">
        <Page
          :total="total"
          show-total
          @on-change="pageChange"
          :current="searchForm.pn"
          show-elevator
          :page-size="searchForm.size"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="show"
      width="50%"
      :before-close="handleClose">
      <div class="dialog-content">
        <el-form :model="dialogForm" :rules="rules" ref="dialogForm" label-width="120px" class="demo-dialogForm">
          <el-form-item label="文件公示名" prop="name">
            <el-input v-model="dialogForm.name" size="small" clearable placeholder="请输入文件名"></el-input>
          </el-form-item>
          <el-form-item label="文件公示时间" prop="date">
            <el-date-picker
              size="small"
              v-model="dialogForm.date"
              type="date"
              placeholder="选择日期"
              value-format="yyyyMMdd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="公示文件">
            <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :limit="1"
              accept=".pdf"
              :before-upload="beforeUpload"
              :on-success="handleSuccess"
              :on-error="handleError"
              :file-list="fileList"
              :on-remove="handleRemove">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip" style="color: red;">只能上传PDF文件，且不超过20M。如需替换文件请先删除下面的文件再上传！</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="submitDialog('dialogForm')">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { 
  queryCertificateList,
  updateCertificate,
  certificateSave,
  delcertificate
 } from "@/api/api2"
export default {
  name:"admin-Health",
  data(){
    return {
      show:false,
      dialogTitle:'添加',
      showText:'',
      type:"",
      searchForm:{
        pn:1,
        size:10,
        type:'1',
        name:""
      },
      total:0,
      tableData:[],
      uploadUrl:'https://www.eut.org.cn/pikachu/certificate/upload',
      fileList:[],
      dialogForm:{
        date:"",
        fileName: "",
        name: "",
        type: "1"
      },
      rules:{
        name:[ { required:true,message:"文件名称必填",trigger:"blur"}],
        date:[{ required:true,message:"公示时间必填",trigger:"blur"}]
      }
    }
  },
  mounted(){
    this.checkParams()
    this.queryTableData()
  },
  methods:{
    checkParams(){
      let params = this.$route.query
      if(JSON.stringify(params) == '{}' || params.token !='eutgwgszywsgs') {
        this.$confirm('您未登陆账号, 是否前往登陆?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '返回首页',
          type: 'warning'
        }).then(() => {
          window.location = 'https://www.eut.org.cn/admin/common/login.html'
        }).catch(() => {
          this.$router.push('/') 
        });
      }
    },
    handleIshow(d){
      console.log(d)
      let _this = this
      d.isShow == 0 ? _this.showText='隐藏' : _this.showText='显示'
      _this.$confirm(`此操作将在前台页面${_this.showText}该条数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        let params = {
          isShow:d.isShow == 0 ? 1 : 0,
          date: d.date,
          fileName: "",
          id: d.id,
          name: d.name,
          type: d.type
        }
        updateCertificate(params).then(res =>{
          if(res.code == '200') {
            _this.$message({
              type: 'success',
              message: `已${_this.showText}!`
            });
            _this.queryTableData()
          }
        })
      }).catch(() => {
        _this.$message({
          type: 'info',
          message: '已取消'
        });          
      });
    },
    showDialog(d,type){
      this.type = type
      if(type == 'edit'){
        this.dialogTitle = '修改'
        this.dialogForm.id = d.id
        this.dialogForm.name = d.name
        this.dialogForm.date = d.date
        this.dialogForm.fileName = ''
        this.fileList = [{
          name:d.fileName,
          url:d.filePath
        }]
      }else {
        this.dialogTitle = '添加'
        this.fileList = []
      }
      this.show = true
    },
    deleteRow(d){
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delcertificate({
            id:d.id
          }).then(res =>{
            if(res.code == '200'){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.queryTableData()
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    handleClose(){
      this.dialogForm.name = ''
      this.dialogForm.date = ''
      this.dialogForm.fileName = ''
      this.fileList = []
      this.show = false
    },
    async queryTableData(){
      let res = await queryCertificateList(this.searchForm)
      if(res.code == '200') {
        this.tableData = res.result
        this.total = res.total
      }
    },
    queryForm(){
      this.searchForm.pn = 1
      this.queryTableData()
    },
    pageChange(v){
      this.searchForm.pn = v
      this.queryTableData()
    },
    beforeUpload(file){
      console.log(file)
      if(file.type !='application/pdf') {
        this.$message({
          message: '只能上传.pdf的文件，请重新选择！',
          type: 'warning'
        });
        return false
      }
      if(file.size>20*1024*1024) {
        this.$message({
          message: '只能上传20M以内的pdf的文件，请重新选择！',
          type: 'warning'
        });
        return false
      }
    },
    handleSuccess(res, file, fileList){
      this.dialogForm.fileName = res.result
      this.fileList = fileList
    },
    handleError(err, file, fileList) {
      console.log(err)
    },
    handleRemove(file, fileList){
      this.fileList = fileList
    },
    submitDialog(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.fileList.length == 0) {
            this.$message({
              type: 'error',
              message: '请上传公示文件'
            });
            return
          }
          if(this.type == 'add') {
            delete this.dialogForm.id
            certificateSave(this.dialogForm).then(res =>{
              if(res.code == '200') {
                this.$message({
                  type: 'success',
                  message: '添加成功！'
                }); 
                this.handleClose()
                this.queryTableData()
              }
            })
          }else{
            updateCertificate(this.dialogForm).then(res =>{
              if(res.code == '200') {
                this.$message({
                  type: 'success',
                  message: '修改成功！'
                }); 
                this.handleClose()
                this.queryTableData()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width:100%;
  height: 100%;
  padding: 20px;
  .head {
    display: flex;
    justify-content: space-between;
  }
}
</style>